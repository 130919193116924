p{
    margin: 0;
}

.myDownloads-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin: 16px 22px;

    .back-icon {
        cursor: pointer;
    }

    p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        line-height: 21px;
    }
}

.myDownloads-container{
    margin: 0px 16px;
}

.download-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .download-item {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .pdf-icon {
    color: #ff5757;
    margin-right: 10px;
    font-size: 24px;
  }
  
  .pdf-title {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #37434E;
    line-height: 24px;
  }