

  .overAll-course-contaniner{
   
    p{
        margin: 0;
    }

    .course-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        margin: 16px 22px;


        .back-icon{
            cursor: pointer;
        }

        p{
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
        }
    }
    .courses-container {
        margin-top: 32px;
        margin-bottom: 70px;
      
        .courses-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
      
          p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 16px;
            color: #1B2228;
            line-height: 21px;
          }
      
          .see-all {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 14px;
            color: #3B82F6;
            line-height: 17px;
      
            &:hover {
              text-decoration: underline;
            }
          }
        }
      
        .courses-grid {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          column-gap: 1.5rem;
          row-gap: 1.4rem;    

          .course-card {
            width: 21%;
            height: 270px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 10px;
            cursor: pointer;
    
            .course-image {
              width: 100%;
              height: 136px;
              border-radius: 8px;
              margin-bottom: 8px;
            }
            .course-image-default{
              background-color: #dfdfdf;
            }
    
            .course-tag {
              display: flex;
              flex-direction: row;
              gap: 12.96px;
              color: #2D81F7;
              font-size: 10px;
              font-family: "Montserrat";
              font-weight: 500;
              line-height: 13.36px;
    
              .course-type {
                font-size: 10px;
                font-family: 'Montserrat';
                font-weight: 500;
                line-height: 13.36px;
                color: #2D81F7;
              }
            }
    
            .course-clusterGroup {
              font-size: 10px;
              font-family: 'Montserrat';
              font-weight: 600;
              line-height: 10px;
              color: #031A44;
              margin-top: 4px;
              text-transform: uppercase;
              display: -webkit-box;
             -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            }
    
            .course-title {
              font-size: 12px;
              font-family: 'Montserrat';
              text-overflow: ellipsis;
              font-weight: 600;
              line-height: 14.17px;
              color: #1B2228;
              margin-top: 4px;
              text-transform: capitalize;
              display: -webkit-box;
             -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             overflow: hidden;
            }
      
            .course-details {
              font-size: 12px;
              color: #666;
              display: flex;
              flex-direction: row;
              margin-top: 7px;
              align-items: center; 
            
              .courseHours {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 10px;
                line-height: 14.58px;
                color: #37434E;
                padding-right: 4px;
              }
            
              .courseTests {
                padding-left: 4px;
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 10px;
                line-height: 14.58px;
                color: #37434E;
                padding-right: 4px;
              }
      
              .courseDocs {
                padding-left: 4px;
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 10px;
                line-height: 14.58px;
                color: #37434E;
              }
            
              .vertical-line {
                width: 1px;
                height: 20px;
                background-color: #7A8B94; 
                margin: 0 4px; 
              }
            }
          }
        }
      }
  }

  .no-data-found-wrapper.course-wrapper {
    height: calc(100vh - 123px);
  }