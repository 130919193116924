.content-overAll-container {
  p {
    margin: 0;
  }

  width: 80%;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 100px;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    .back-icon {
      cursor: pointer;
    }

    p {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;
      color: #000000;
      line-height: 21px;
      text-transform: capitalize;
    }
  }

  .price-card {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #fff;
    height: 60px;
    margin-top: 16px;

    .price-info {
      display: flex;
      flex-direction: column;

      .current-price {
        color: #000000;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        margin: 0;


      }

      .discount-info {
        display: flex;
        align-items: center;

        .original-price {
          font-size: 1em;
          color: #888;
          text-decoration: line-through;
          margin-right: 10px;

        }

        .discount {
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: #EB5757;
        }
      }
    }

    .buy-button {
      padding: 9px 36px;
      background-color: #2D81F7;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      font-family: "Montserrat";

    }
  }

  .content-container {
    width: 100%;
    margin-top: 16px;


    .content-wrapper {
      display: flex;
      flex-direction: row;
      padding: 16px;
      background-color: #FFFFFF;
      border-radius: 6px;
      gap: 24px;

      .content-video-container {
        width: 60%;

      }

      .content-overview-container {
        width: 40%;

        .educator-card {
          .educator-title {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 10px;
          }

          .scroll-bar {
            overflow: scroll;
            height: 175px;

            .educator-info {
              display: flex;
              align-items: center;
              padding: 8px 10px;
              border: 1px solid #E1E7EA;
              border-radius: 3px;
              height: 66px;
              margin-bottom: 18px;

              .educator-image {
                border: 0.5px solid #BDBDBD;
                border-radius: 3px;

                img {
                  width: 42px;
                  height: 42px;
                }
              }

              .educator-details {
                margin-left: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                .educator-details-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;


                  .educator-name {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    color: #000000;
                    text-transform: capitalize;
                  }

                  .educator-followers {
                    margin-top: 3px;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #60717A;

                  }

                }


                .follow-button {
                  padding: 5px 16px;
                  font-size: 12px;
                  font-weight: 500;
                  font-family: 'Montserrat';
                  color: #000000;
                  line-height: 14.63px;
                  border: 0.7px solid #000000;
                  border-radius: 4px;
                  cursor: pointer;
                  width: 85px;

                  .follow {
                    // margin-top: 8px;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13.41px;
                    color: #1B2228;
                    cursor: pointer;
                  }

                  .follow {
                    // margin-top: 8px;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13.41px;
                    color: #1B2228;
                    text-decoration: none;
                    cursor: pointer;

                    &.following {
                      color: #07BD81;
                    }

                    &:not(.following) {
                      color: #000000;
                    }
                  }

                }
              }
            }
          }
        }

        .study-material {
          .study-material-title {
            font-size: 14px;
            font-weight: 600;
            font-family: 'Montserrat';
            color: #37434E;
            line-height: 18px;
          }

          .pdf-scroll {
            overflow: scroll;
            height: 160px;

            .pdf-container {
              margin-top: 8px;
              display: flex;
              flex-direction: column;
              overflow-y: auto;

              .pdf {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 1px solid #CBD5E1;
                padding: 10px 0px;

                .pdf-content-wrapper {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 8px;
                }

                .download-icon {
                  cursor: pointer;
                }
                .download-icon{
                  span{
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #07BD81;
                  }
                }
              }
            }
          }
        }
      }

    }


  }

  .content-about {
    margin-top: 16px;
    width: 64%;

    .content-type-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 13px;
      margin-bottom: 6px;

      .content-type {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 12px;
        color: #2D81F7;
        line-height: 13.36px;
      }

      .language-icon {
        width: 16.92px;
        height: 13.82px;
      }
    }

    .content-title {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      color: #021F55;
      line-height: 24px;
      margin-bottom: 6px;
      text-transform: capitalize;
    }

    .content-description {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 12px;
      color: #1B2228;
      line-height: 20px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px 0;
    .upnexttext {
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: #1B2228;
    }
    .scrollview {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .item-container {
        display: flex;
        padding: 8px 16px;
        gap: 7px;
        background-color: #ffffff;
        width: 59%;
        cursor: pointer;
        transition: background-color 0.3s ease;

      }
      .item-container.active {
        border:1px solid #07BD81;
        font-weight: bold;
      }
      .playicon-container {
        color: #07BD81;
        margin-right: 6px;
      }
      .content-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .content-name {
          font-size: 12px;
          font-weight: 500;
          color: #021F55;
          font-family: 'Montserrat';
          line-height: 18px;
          text-transform: capitalize;

        }
        .content-duration {
          color: #7A8B94;
          font-family: 'Montserrat';
          font-size: 13px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
  }

  .content-recommended-test {
    margin-top: 16px;
    width: 64%;

    .recommended-test {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #1B2228;
      margin-bottom: 8px;
      text-transform: capitalize;

    }

    .test-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .test-card {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 8px;
        width: 30%;
        cursor: pointer;

        .test-info {
          .test-type {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 12.19px;
            color: #D06200;
            margin-bottom: 6px;

            .divider {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }

            .test-category {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #2D81F7;
              text-transform: capitalize;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .test-title {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #021F55;
            margin-bottom: 6px;
            text-transform: capitalize;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .test-details {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-bottom: 10px;
            width: 100%;

            span {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }
          }
        }

        .take-test-button {
          background-color: #07BD81;
          color: white;
          padding: 0px 20px;
          border-radius: 4px;
          cursor: pointer;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
        }
        .paid-text{
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #EB5757;
        }
      }
    }
  }

}