.coachmi-container {
  p {
    margin: 0;
  }

  width: 100%;
  padding: 22px 16px 100px 16px;

  .coachmi-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slick-slider {

      .slick-arrow {
        display: none !important;
      }

      .slick-next {
        display: none;
      }
    }


    .user-container {
      .userProfile {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-image {
          .profile {
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }
        }

        .user-details {
          margin-left: 16px;

          .user-name {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 16px;
            line-height: 19.5px;
            color: #1E293B;
            text-transform: lowercase;
          }

          .user-name::first-letter {
            text-transform: uppercase;
          }

          .tag {
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: 12px;
            line-height: 14.63px;
            color: #1E293B;
          }
        }
      }
    }

    .search {
      width: 60%;
      height: 40px;
      border-radius: 6px;
      border: 1px;
      border-color: #B8C3C9;

      .ant-input-affix-wrapper {
        border-radius: 6px;
      }

      .ant-list {
        z-index: 1;
        background: white;
        max-height: 350px;
        overflow-y: scroll;

        .ant-list-items {
          .ant-list-item {
            padding: 12px 20px;
            cursor: pointer;
          }
        }
      }

      .global-search-no-data-found {
        justify-content: center;
      }

      .data-type {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 19.5px;
        color: #1E293B;
      }
    }

    .cluster-dropdown {
      width: 10%;
      height: 36px;

      .ant-select-selection-item {
        font-weight: 600;
        color: #1E293B;
      }

      .ant-select-show-arrow {
        .ant-select-selector {
          border-radius: 5px;
          font: 600 15px / 18px 'Montserrat';
          background-color: #E1E7EA;
        }
      }
    }
  }

  .coachmi-content-section{
    margin-bottom: 32px;
    .banner-image {
      margin-top: 18px;
  
      .ant-image {
        width: 100%;
        height: 231px;
      }
    }

  }

  .my-coachmi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .my-courses {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background: white;
      width: 48%;
      padding: 15px;
      border: 1px solid #E1E7EA;
      border-radius: 6px;
      cursor: pointer;

      .my-courses-title{
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
    }

    .my-downloads {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 48%;
      gap: 20px;
      background: white;
      padding: 15px;
      border: 1px solid #E1E7EA;
      border-radius: 6px;
      cursor: pointer;

      .my-downloads-title{
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
    }
  }

  .coachmi-features {
    cursor: pointer;

    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      justify-content: center;
      margin-top: 32px;
    }

    .card {
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px;
      width: 100%;
      border: 1px solid #E1E7EA;
      height: 80px;
      position: relative; 
    }

    .icon {
      .icon img {
        width: 24px;
        height: 24px;
      }
    }

    .card-content {
      .card-title {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #021F55;
        margin-bottom: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .card-description {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #37434E;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

    }

    .right-arrow {
      position: absolute;
      top: 8px;  
      right: 8px; 
      cursor: pointer;
    }
  }

  .subscription-container {
    border-radius: 6px;
    background-color: #031A44;
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 20;

    .subscription {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .subscription-text {
        .clusterName {
          font-family: 'Montserrat';
          font-weight: '400';
          font-size: 14px;
          line-height: 21px;
          color: #FFFFFF;
        }

        .clusterPrice {
          font-family: 'Montserrat';
          font-weight: '500';
          font-size: 12px;
          line-height: 18px;
          color: #FFA443;
        }
      }

      .subscription-btn {
        .coachm-btn {
          border: none !important;
          font-family: "Montserrat" !important;
          font-size: 10px !important;
          font-weight: 600 !important;
          line-height: 11px !important;
          color: #FFFFFF;
        }
      }
    }
  }

  .exam-courses-container {
    margin-top: 32px;

    .cluster-group-header {
      width: 99%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        color: #1B2228;
        line-height: 21px;
      }

      .see-all {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #3B82F6;
        line-height: 17px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .courses {
      display: flex;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap !important;
      cursor: pointer;

      .course-card {
        background-color: #fff;
        border: 1px solid #E1E7EA;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        padding: 10px;
        width: 19%;
        height: 52px;

        .empty-image-icon {
          width: 32px;
          height: 32px;
          background: #dfdfdf;
          border-radius: 50%;
        }

        .course-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .clusterGroupName {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
          line-height: 14.63px;
          color: #1B2228;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 70%;
          text-transform: capitalize;
        }
      }
    }


  }

  .courses-container {
    margin-top: 32px;
    width: 100%;

    .courses-header {
      width: 99%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        color: #1B2228;
        line-height: 21px;
      }

      .see-all {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #3B82F6;
        line-height: 17px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .courses-grid {
      display: flex;
      width: 100%;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap !important;

      .course-card {
        width: 21%;
        height: 296x;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;

        .course-image {
          width: 100%;
          height: 156px;
          border-radius: 8px;
          margin-bottom: 8px;
        }

        // .course-image-default {
        //   background-color: #dfdfdf;
        // }

        .course-tag {
          display: flex;
          flex-direction: row;
          gap: 12.96px;
          color: #2D81F7;
          font-size: 10px;
          font-family: "Montserrat";
          font-weight: 500;
          line-height: 13.36px;

          .course-type {
            font-size: 10px;
            font-family: 'Montserrat';
            font-weight: 500;
            line-height: 13.36px;
            color: #2D81F7;
          }
        }

        .course-clusterGroup {
          font-size: 10px;
          font-family: 'Montserrat';
          font-weight: 600;
          line-height: 10px;
          color: #031A44;
          margin-top: 4px;
          text-transform: uppercase;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .course-title {
          font-size: 12px;
          font-family: 'Montserrat';
          text-overflow: ellipsis;
          font-weight: 600;
          line-height: 14.17px;
          color: #1B2228;
          margin-top: 4px;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .course-details {
          font-size: 12px;
          color: #666;
          display: flex;
          flex-direction: row;
          margin-top: 7px;
          align-items: center;

          .courseHours {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 14.58px;
            color: #37434E;
            padding-right: 4px;
          }

          .courseTests {
            padding-left: 4px;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 14.58px;
            color: #37434E;
            padding-right: 4px;
          }

          .courseDocs {
            padding-left: 4px;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 14.58px;
            color: #37434E;
          }

          .vertical-line {
            width: 1px;
            height: 20px;
            background-color: #7A8B94;
            margin: 0 4px;
          }
        }
      }
    }
  }

  .refer-friend-container {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    .refer-friend-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .refer-friend-text {
        flex: 1;
        margin-right: 20px;
        padding: 24px 16px;

        .refer-text-1 {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #1B2228;
        }

        .refer-text-2 {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #1B2228;
          margin-top: 3px;
          margin-bottom: 12px;
        }

        .refer-friend-btn {
          border-radius: 4px;
          padding: 8px, 11.23px, 8px, 11.23px;
          background-color: #2D81F7;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 12px;
          line-height: 11px;
          color: #FFFFFF;
        }
      }

      .refer-friend-image {
        img {
          width: 331.19px;
          height: 162.86px;
        }
      }

    }
  }

  .free-classes-container {
    border-radius: 8px;
    margin-top: 32px;
    width: 100%;

    .header {
      width: 99%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      .see-all {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #3B82F6;
        line-height: 17px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .classes {
      width: 100%;
      display: flex;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap !important;
      // display: flex;
      // gap: 24px;
    }

    .class-card {
      background-color: white;
      border-radius: 12px;
      overflow: hidden;
      padding: 16px;
      // width: 343px;
      width: 24%;
      // height: 270px;

      .class-image {
        width: 100%;
        height: auto;
      }

      .class-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 10px;
        // padding: 7px;

        .class-text {
          display: flex;
          flex-direction: column;
          width: 70%;

          .class-title {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 13px;
            line-height: 18.58px;
            color: #1B2228;
            overflow: hidden;
            text-transform: capitalize;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-transform: capitalize;

          }

          .class-instructor {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #37434E;
            overflow: hidden;
            text-transform: capitalize;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-transform: capitalize;
          }
        }

        .class-type {
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #2D81F7;
        }
      }
    }
  }

  .educators-container {
    border-radius: 8px;
    margin-top: 32px;

    .header {
      width: 99%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      .see-all {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #3B82F6;
        line-height: 17px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .educators {
      display: flex;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap !important;
    }

    .educator-card {
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: white;
      border-radius: 6px;
      overflow: hidden;
      padding: 16px;
      width: 19%;
      margin-bottom: 20px;

      .educator-image {
        width: 48px;
        height: 48px;
        border: 0.57px solid #BDBDBD;
        border-radius: 36.57px;
      }

      .educator-text {
        display: flex;
        flex-direction: column;

        .educator-name {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #1B2228;
          text-transform: capitalize;
        }

        .educator-follow {
          margin-top: 3px;
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
          line-height: 14.63px;
          color: #1B2228;
        }

        .follow {
          margin-top: 8px;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 11px;
          line-height: 13.41px;
          color: #1B2228;
          text-decoration: underline;
          cursor: pointer;

          &.following {
            color: #07BD81;
          }

          &:not(.following) {
            color: #2D81F7;
          }
        }

      }

    }
  }

  .community-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 24px;
    margin-top: 32px;

    .community-content {
      display: flex;
      flex-direction: column;
      max-width: 60%;

      .community-header {
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-bottom: 16px;

        .heading {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #1B2228;
          margin: 0;
        }

        .active-members {
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 22.5px;
          color: #7A8B94;

        }

      }

      .join-button {
        background-color: #2D81F7;
        color: white;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        margin-bottom: 19px;
        width: 168px;
        padding: 8px 24px 8px 24px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 17.07px;

        &:hover {
          background-color: #0056b3;
        }
      }

      .community-features {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 49px;
        row-gap: 21px;


        .feature-item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 14px;
          line-height: 22.5px;
          color: #000000;

          .community-icon {
            margin-right: 5px;
          }
        }
      }
    }

    .community-image {
      img {
        width: 100%;
        max-width: 200px;
        border-radius: 8px;
      }
    }
  }

  .test-series {
    margin-top: 32px;

    .header {
      width: 99%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      .see-all {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #3B82F6;
        line-height: 17px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .test-cards {
      width: 80%;
      display: flex;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap !important;
      cursor: pointer;

      .test-card {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 8px;
        width: 24%;

        .test-info {
          .test-type {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 12.19px;
            color: #D06200;
            margin-bottom: 6px;
            display: flex;
            gap: 5px;

            .divider {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }

            .test-category {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #2D81F7;
              text-transform: capitalize;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-transform: capitalize;
            }
          }

          .test-title {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #021F55;
            text-transform: capitalize;
            margin-bottom: 6px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: capitalize;
          }

          .test-details {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-bottom: 10px;
            width: 100%;

            span {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }
          }
        }

        .take-test-button {
          background-color: #07BD81;
          color: white;
          padding: 0px 20px;
          border-radius: 4px;
          cursor: pointer;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }

  }

}

.coachmi-content-section {
  .banner-image {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .ant-image {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}