.course-content-screen {
    p {
        margin: 0;
    }
    padding: 20px;

    .content-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 25px;

        .back-icon {
            cursor: pointer;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
        }
    }

    .price-card {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        border-radius: 8px;
        background-color: #fff;
        height: 60px;


        .price-info {
            display: flex;
            flex-direction: column;

            .current-price {
                color: #000000;
                font-family: "Montserrat";
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                margin: 0;


            }

            .discount-info {
                display: flex;
                align-items: center;

                .original-price {
                    font-size: 1em;
                    color: #888;
                    text-decoration: line-through;
                    margin-right: 10px;

                }

                .discount {
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #EB5757;
                }
            }
        }

        .buy-button {
            padding: 9px 36px;
            background-color: #2D81F7;
            color: #FFFFFF;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            font-family: "Montserrat";

        }
    }


    .content-filter {
        display: flex;
        justify-content: end;
        padding-bottom: 8px;

        .form-control {
            display: flex;
            align-items: center;
            column-gap: 16px;
                cursor: pointer;
        
                .filter-title {
                    font: 400 13px/15px 'Montserrat';
                    color: #000000;
                    font-family: Montserrat;
                }

            .ant-select {
                width: 190px;
                .ant-select-selector {
                    background-color: unset;
                    border: 1px solid #B8C3C9;
                    border-radius: 4px;
                }
            }
        }
    }

        .courses-grid {
            height: calc(100vh - 222px);
            overflow-y: scroll;
            .content-scroll {
                display: flex;
                width: 100%;
                row-gap: 16px;
                column-gap: 12px;
                flex-wrap: wrap;
    
                .content-item {
                    width: 49%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    border-bottom: 1px solid #ddd;
                    background-color: #fff;
                    border-radius: 8px;
                    cursor: pointer;
    
                    .content-icon {
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
    
                    .content-details {
                        flex: 1;
                        margin-left: 20px;
    
                        .content-title {
                            font-family: "Montserrat";
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            color: #021F55;
                            text-transform: capitalize;
    
                        }
    
                        .content-subtopic {
                            font-family: "Montserrat";
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14px;
                            color: #37434E;
                        }
                    }
    
                    .content-arrow {
                        img {
                            cursor: pointer;
                        }
                    }
                }
            }
    
        }

        .no-data-found-wrapper {
            height: calc(100vh - 222px);
        }
}