.overAll-course-contaniner {
    padding: 20px;

    p {
        margin: 0;
    }

    .course-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        margin: 16px 22px;

        .back-icon {
            cursor: pointer;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
        }
    }

    .free-classes-container {
        width: 100%;
        .educators {
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.5rem;
            row-gap: 1.4rem;

            .educator-card {
                display: flex;
                align-items: center;
                gap: 12px;
                background-color: white;
                border-radius: 6px;
                overflow: hidden;
                padding: 16px;
                width: 19%;

                .educator-image {
                    width: 48px;
                    height: 48px;
                    border: 0.57px solid #BDBDBD;
                    border-radius: 36.57px;
                }

                .educator-text {
                    display: flex;
                    flex-direction: column;

                    .educator-name {
                        font-family: 'Montserrat';
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18px;
                        color: #1B2228;
                        text-transform: capitalize;
                    }

                    .educator-follow {
                        margin-top: 3px;
                        font-family: 'Montserrat';
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14.63px;
                        color: #1B2228;
                    }

                    .follow {
                        margin-top: 8px;
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 13.41px;
                        color: #1B2228;
                        text-decoration: underline;
                        cursor: pointer;

                        &.following {
                            color: #07BD81;
                        }

                        &:not(.following) {
                            color: #2D81F7;
                        }
                    }

                }

            }
        }
    }
}