.cluster-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .cluster-carousel-dots {
        display: none;
    }

    .cluster-modal-content {
        background: white;
        width: 35%;
        border-radius: 8px;
        text-align: center;

        .cluster-modal-content-header {
            padding: 20px 20px 0px 20px;
            display: flex;
        }

        .cluster-modal-content-body {
            padding: 20px;
            .dropdown {
                width: 100%;
                padding: 8px;
            }

            .cluster-carousel {
                margin: 20px 0px;

                .slick-track {
                    width: max-content;
                    display: flex;
                    gap: 20px;
                }

                .cluster-card {
                    display: flex !important;
                    background: #f5f5f5;
                    border-radius: 8px;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    border: 2px solid transparent;
                    padding: 4px;
                    height: 40px;
                    justify-content: center;
                    
                    &.selected {
                        border: 2px solid #007bff;
                    }

                    .cluster-logo {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 10px;
                    }

                    span {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }
            }
        }


        .action-buttons {
            display: flex;
            justify-content: end;
            padding: 0px 20px 20px 20px;

            .no-button {
                background: #ccc;
                color: white;
                border: none;
                padding: 10px 40px;
                border-radius: 4px;
                cursor: pointer;
                margin-right: 20px;
            }

            .yes-button {
                background: #28a745;
                color: white;
                border: none;
                padding: 10px 40px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
}