.overAll-course-contaniner {
    padding: 20px;

    p {
        margin: 0;
    }

    .course-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        margin: 16px 22px;

        .back-icon {
            cursor: pointer;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
        }
    }

    .free-classes-container {
        border-radius: 8px;
        margin-top: 32px;
        width: 100%;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h2 {
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }

            .see-all {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 14px;
                color: #3B82F6;
                line-height: 17px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .classes {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.5rem;
            row-gap: 1.4rem;            // display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        }

        .class-card {
            background-color: white;
            border-radius: 12px;
            overflow: hidden;
            padding: 16px;
            width: 24%;

            .class-image {
                width: 100%;
                height: auto;
            }

            .class-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 10px;
                // padding: 7px;

                .class-text {
                    display: flex;
                    flex-direction: column;
                    width: 70%;

                    .class-title {
                        font-family: 'Montserrat';
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18.58px;
                        color: #1B2228;
                        text-transform: capitalize;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        text-transform: capitalize;
                    }

                    .class-instructor {
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        text-transform: capitalize;
                        color: #37434E;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        text-transform: capitalize;
                    }
                }

                .class-type {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: #2D81F7;
                }
            }
        }
    }
}