.cluster-group-container {
    margin-top: 32px;
    margin-left: 16px;
    p{
        margin: 0px;
    }
    .cluster-group-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        margin-bottom: 22px;


        .back-icon{
            cursor: pointer;
        }

        p{
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
        }
    }

    .courses {
        display: flex;
        column-gap: 0.5rem;
        row-gap: 1.4rem;
        flex-wrap: wrap;
        cursor: pointer;
        width: 100%;
  
        .course-card {
          background-color: #fff;
          border: 1px solid #E1E7EA;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          padding: 10px;
          width: 19%;
          height: 52px;
  
          .course-icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          .course-icon-default {
            background-color: #dfdfdf;
          }
  
          .clusterGroupName {
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: 12px;
            line-height: 14.63px;
            color: #1B2228;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: capitalize;
          }
        }
      }
  
}