.course-container {
    width: 80%;
    margin: 16px;

    .content-header {
        display: flex;
        flex-direction: row;
        gap: 12px;

        .back-icon {
            cursor: pointer;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
            text-transform: capitalize;
        }
    }

    .course-cluster-container {
        background-color: #FFFFFF;
        padding: 16px;

        .course-title {
            display: flex;
            justify-content: space-between;

            .course-image-container {
                flex: 1;

                img {
                    width: 356px;
                    height: 242px;
                }
            }

            .course-details-container {
                flex: 2;
                padding: 20px;
                position: relative;

                .share-icon {
                    position: absolute;
                    right: 2px;
                    top: 0;
                    cursor: pointer;

                }

                .course-tag {
                    display: flex;
                    gap: 12.96px;
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 13px;
                    color: #2D81F7;

                    span {}

                }

                .course-status {
                    background-color: #eaf5ff;
                    color: #0056b3;
                    padding: 2px 6px;
                    border-radius: 4px;
                    font-size: 12px;
                }

                .course-topic {
                    font-family: "Montserrat";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;
                    color: #1B2228;
                    padding-top: 8px;
                    text-transform: capitalize;

                }

                .course-description {
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #1B2228;

                }
            }
        }

        .course-info-container {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            position: relative;

            .course-info {
                display: flex;
                gap: 24px;

                .course-info-item {
                    border: 1px solid #E1E7EA;
                    border-radius: 8px;
                    display: flex;
                    padding: 10px;
                    width: 175px;
                    height: 55px;

                    .languageImg {
                        p {
                            svg {

                                width: 32px;
                                height: 32px;
                                margin-right: 10px;
                            }
                        }
                    }

                    p {
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12.5px;
                        color: #60717A;
                        gap: 3px;
                        display: flex;
                        flex-direction: column;
                        gap: 3px;

                        .span-tag {
                            font-family: "Montserrat";
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            color: #000000;

                        }
                    }
                }
            }

            .subscription-button {
                background: #2D81F7;
                font-family: "Montserrat";
                font-size: 10px;
                font-weight: 600;
                line-height: 11px;
                color: #FFFFFF;
                border-radius: 4px;
                position: absolute;
                right: 0;
                bottom: 1px;
            }
        }
    }

    //free class style

    .free-classes {
        margin-top: 20px;

        p {
            color: #07BD81;
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            // margin-bottom: 2px;

        }

        .classes-list {
            display: flex;
            gap: 10px;

            .class-card {
                border: 1px solid #E1E7EA;
                background: #FFFFFF;
                width: 291px;
                height: 95px;
                padding: 8px 10px;
                gap: 10px;
                border-radius: 4px;
                display: flex;


                .class-image {
                    width: 80px;
                    height: 80px;
                }

                .class-image-default {
                    background-color: #dfdfdf;
                }

                .class-details {
                    width: 175px;
                    height: 80px;

                    .freeclass-title {
                        font-family: "Montserrat";
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19.5px;
                        margin: 0;
                        color: #000000;
                    }

                    .date-time {
                        color: #7A8B94;
                        font-family: "Montserrat";
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 14.58px;
                    }

                    .free-class-instructor {
                        font-family: "Montserrat";
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 14.58px;
                        color: #7A8B94;
                    }
                }
            }
        }
    }

    //content style

    .content-list {
        padding: 20px;

        p {
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #000000;
        }

        .content-scroll {
            // height: 400px;
            height: calc(100vh - 536px);
            overflow-y: auto;
            overflow-x: hidden;
        }

        .content-item {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ddd;
            background-color: #fff;
            margin-bottom: 18px;
            border-radius: 8px;
            cursor: pointer;

            .content-icon {
                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .content-details {
                flex: 1;
                margin-left: 20px;

                .content-title {
                    font-family: "Montserrat";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #021F55;
                    text-transform: capitalize;

                }

                .content-subtopic {
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                    color: #37434E;
                }
            }

            .content-arrow {
                img {
                    cursor: pointer;
                }
            }
        }
    }

}