.app-container {
  width: 80%;
  padding: 20px;

  .content-header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 30px;

    .back-icon {
      cursor: pointer;
    }

    p {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
    }
  }

  .course-title {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: #000000;
    margin-bottom: 24px;

  }

  .content {
    display: flex;
    gap: 20px;

    .price-plan-container {
      display: flex;
      flex-direction: column;

      .plan {
        margin-bottom: 10px;
        width: 421px;
        height: 83px;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        background-color: #FFFFFF;
        cursor: pointer;
        display: flex;
        justify-content: space-around;

        .subscription-duration {

          font-family: "Montserrat";
          font-size: 18px;
          font-weight: 500;
          line-height: 36px;
          color: #07BD81;
        }

        .subscription {
          gap: 8px;
          display: flex;
          flex-direction: column;

          .subscription-price {
            font-family: "Montserrat";
            font-size: 18px;
            font-weight: 600;
            color: #1B2228;
            line-height: 22px;
          }

          .subscription-permonth {
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #828E94;

          }
        }

      }
      .plan-selected {
        border: 1px solid #2D81F7;
        cursor: not-allowed;
      }
    }

    .summary-section {
      width: 475px;
      background-color: #FFFFFF;
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 13px;
      
      .user-details {
        border-radius: 5px;
        .sub-plan {
          display: flex;
          justify-content: space-between;

          .validity {
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: #1B2228;
          }

          .user-details-subscription {
            gap: 6px;
            display: flex;
            flex-direction: column;

            .price {
              font-family: "Montserrat";
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              color: #07BD81;
            }

            .discount {
              font-family: "Montserrat";
              font-size: 10.37px;
              font-weight: 500;
              line-height: 18.15px;
              color: #1B74E4;
              background-color: #CDE3FF;
            }
          }
        }

        .details {
          padding: 10px 8px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-radius: 6px;
          border: 0.86px solid var(--BlueGray-BlueGray-300, #CBD5E1);
          margin-top: 20px;

          .user-name {
            display: flex;
            justify-content: space-between;
            font-family: "Montserrat";
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            color: #334155;

            span {
              font-family: "Montserrat";
              font-size: 13px;
              font-weight: 600;
              line-height: 18.15px;
              color: #000000;
            }
          }
        }
      }

      .discount-section {
        display: flex;
        flex-direction: column;
        row-gap: 13px;
        border-radius: 5px;

        .points {
          position: relative;
          display: flex;
          gap: 3px;
          font-family: "Montserrat";
          font-size: 13px;
          font-weight: 500;
          border: 0.86px solid #CBD5E1;
          padding: 10px 8px;
          border-radius: 6px;
          height: 44px;
          align-items: center;

          .input-filed {
            position: absolute;
            right: 8px;
            border: 1px solid #CBD5E1
          }
        }

        .coupon {
          display: flex;
          cursor: pointer;
          position: relative;
          gap: 3px;
          font-family: "Montserrat";
          font-size: 13px;
          font-weight: 500;
          border: 0.86px solid #CBD5E1;
          padding: 10px 8px;
          border-radius: 6px;
          height: 44px;

          p {}

          .coupan-image {
            position: absolute;
            right: 0;
          }
        }
      }

      .payment-summary {
        .to-be-paid {
          display: flex;
          justify-content: space-between;
          font-family: "Montserrat";
          font-size: 13px;
          font-weight: 500;
          line-height: 15px;
          color: #7A8B94;
          border: 0.86px solid #CBD5E1;
          padding: 10px 8px;
          border-radius: 6px;

          span {
            color: #334155;
            font-family: "Montserrat";
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
          }

        }

        .pay-now {
          font-family: "Montserrat";
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          cursor: pointer;
          color: #FFFFFF;
          background-color: #2D81F7;
          padding: 7px 31px;
          border: 0.86px solid #CBD5E1;
          width: 444px;
          border-radius: 6px;
          height: 44px;
          margin-top: 13px;
          width: 440px;
        }

        .pay-now:hover {
          background-color: #0056b3;
        }
      }
    }
  }

}