.test-series-container{
    p {
        margin: 0;
    }

    width: 80%;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .test-series-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;

        .back-icon{
            cursor: pointer;
        }

        p{
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
            text-transform: capitalize;
        }
    }

    .test-cards {
      display: flex;
      column-gap: 0.5rem;
      row-gap: 1.4rem;
      flex-wrap: wrap;
      cursor: pointer;


      .test-card {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 8px;
        width: 24%;

        .test-info {
          .test-type {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10px;
            line-height: 12.19px;
            color: #D06200;
            margin-bottom: 6px;
            display: flex;

            .divider {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }

            .test-category {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              text-transform: capitalize;
              color: #2D81F7;
              display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: capitalize;
            }
          }

          .test-title {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #021F55;
            text-transform: capitalize;
            margin-bottom: 6px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: capitalize;
          }

          .test-details {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-bottom: 10px;
            width: 100%;

            span {
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: #60717A;
            }
          }
        }

        .take-test-button {
          background-color: #07BD81;
          color: white;
          padding: 0px 20px;
          border-radius: 4px;
          cursor: pointer;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
}