.cluster-group-course {
    p {
        margin: 0;
    }

    width: 100%;
    padding: 16px;

    .content-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 16px;

        .back-icon {
            cursor: pointer;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
        }
    }

    .cluster-group-header {
        background-color: #07BD81;
        color: white;
        padding: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        .cluster-image {
            display: flex;
            gap: 10px;

            .left-vector {
                width: 7px;
                height: 6px;
                position: absolute;
                bottom: 20px;
            }

            .right-vector {
                width: 7px;
                height: 6px;
                top: -3px;
                right: 10px;
                position: absolute;
            }

            p {
                font-family: "Montserrat";
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                width: 70%;

                span {
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 20px;
                    text-align: left;

                }
            }
        }

        .cluster-group-subscribe {
            background-color: #FFFFFF;
            border: none;
            width: 87px;
            color: #00134A;
            padding: 8px 12px;
            font-family: "Montserrat";
            cursor: pointer;
            border-radius: 14px;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
        }
    }

    .courses-grid {
        gap: 16px;
        padding-top: 16px;
        display: flex;
        flex-wrap: wrap;

        .course-card {
            width: 21%;
            background-color: #fff;
            cursor: pointer;
            border-radius: 8px;
            padding: 10px;

            p {
                margin: 0px;
            }

            .course-image {
                width: 100%;
                height: 136px;
                border-radius: 8px;
                margin-bottom: 8px;
            }

            .course-image-default {
                background-color: #dfdfdf;
            }

            .course-tag {
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-bottom: 8px;
                align-items: center;

                .course-type {
                    color: #2D81F7;
                    font-size: 10px;
                    font-family: "Montserrat";
                    font-weight: 500;
                }

            }

            p {
                font-size: 12px;
                margin: 0 0 8px;
                font-family: "Montserrat";
                font-weight: 600;
                line-height: 14.17px;
                color: #021F55;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-transform: capitalize;
            }

            .course-details {
                font-size: 12px;
                color: #666;
                display: flex;
                flex-direction: row;
                align-items: center;
                p {
                    margin: 0px;
                }

                .courseHours {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14.58px;
                    color: #37434E;
                    padding-right: 4px;
                }

                .courseTests {
                    padding-left: 4px;
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14.58px;
                    color: #37434E;
                    padding-right: 4px;
                }

                .courseDocs {
                    padding-left: 4px;
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14.58px;
                    color: #37434E;
                }

                .vertical-line {
                    width: 1px;
                    height: 20px;
                    background-color: #7A8B94;
                    margin: 0 4px;
                }
            }
        }
    }
}